import React from "react";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";

const NotFoundPage: React.FC<any> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="404: Not Found" />
      <h1>404: Not Found</h1>
      <p>指定されたページは無いみたいです。ごめんなさい</p>
    </Layout>
  );
};

export default NotFoundPage;
